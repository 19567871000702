import { Card, FormControl } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";

import { useFormikContext } from "formik";

import { TagsInput } from "react-tag-input-component";

import FormInput from "../form-input/form-input";
import { useEffect, useState } from "react";
import {
  Grid,
  MenuItem,
  Select,
  FormControl as MUFormControl, // Alias for Material-UI FormControl
  Typography,
} from "@mui/material";
import { getAssociationAsync } from "../../redux/b2b/actions/actions";
import { useSelector } from "react-redux";
import { getInsurers } from "../../firebase/firebase.utils";

const EditProduct = ({
  finalPrice,
  productToEdit,
  id,
  setTags,
  tags,
  associations,
}) => {
  const categories = ["Plan de seguros", "B2b"];

  const currency = ["CRC"];

  const exchangeRateList = ["1.00", "2.00"];

  const apportionment = ["Si", "No"];

  const taxPercentaje = ["13%"];

  const { errors, handleChange, values, handleBlur, touched } =
    useFormikContext();

  const isB2b = values.category === "B2b"; // Check if category is B2B

  const [associationData, setAssociationData] = useState(null);
  const [insurers, setInsurers] = useState([]);

  const insurerList = insurers.map((insurerItem) => ({
    insurerId: insurerItem.id,
    label: insurerItem.name,
  }));

  console.log(insurers);

  // Fetch association data based on associationRef
  useEffect(() => {
    const fetchData = async () => {
      const insurers = await getInsurers();
      setInsurers(insurers);

      if (productToEdit && productToEdit.associationRef) {
        try {
          const assoc = await getAssociationAsync(productToEdit.associationRef);
          setAssociationData(assoc);
        } catch (error) {
          console.log("Error fetching association data:", error);
        }
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, [productToEdit]);

  return (
    <Card border="light">
      <Card.Body className="pe-2">
        <Row>
          <Col lg="8">
            {id && (
              <small className="text-muted">
                Registrado{" "}
                {productToEdit?.cdate
                  ? productToEdit.cdate.toDate().toLocaleDateString("es-CR", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })
                  : "--"}
              </small>
            )}

            <Form.Group>
              <FormInput
                label={"Nombre del producto"}
                onChange={handleChange}
                value={values.productName}
                type="text"
                name="productName"
                placeholder={"Plan Bronce"}
                errorList={errors}
                onBlur={handleBlur}
                touched={touched}
              />

              <Form.Control.Feedback type="invalid">
                {errors.productName}
              </Form.Control.Feedback>
            </Form.Group>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label style={{ fontSize: "14px" }} className="mb-3">
                    Categoria
                  </Form.Label>
                  <Form.Select
                    size="sm"
                    onChange={handleChange}
                    name="category"
                    value={values.category}
                    required
                    className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                  >
                    {categories.map((categ, index) => {
                      return (
                        <option key={index} value={categ} name="category">
                          {categ}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <FormInput
                    label={"Codigo ASP"}
                    onChange={handleChange}
                    value={values.ASPCode}
                    type="number"
                    name="ASPCode"
                    placeholder={"121212121"}
                    errorList={errors}
                    onBlur={handleBlur}
                    touched={touched}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.ASPCode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <FormInput
                    label={"CABYS"}
                    onChange={handleChange}
                    value={values.CABYS}
                    type="number"
                    name="CABYS"
                    placeholder={"121212121"}
                    errorList={errors}
                    onBlur={handleBlur}
                    touched={touched}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.CABYS}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label style={{ fontSize: "14px" }} className="mb-3">
                    Prorrateo
                  </Form.Label>
                  <Form.Select
                    size="sm"
                    onChange={handleChange}
                    name="apportionment"
                    value={values.apportionment}
                    required
                    className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                  >
                    {apportionment.map((option, index) => {
                      return (
                        <option key={index} value={option} name="apportionment">
                          {option}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label style={{ fontSize: "14px" }} className="mb-3">
                    Moneda
                  </Form.Label>
                  <Form.Select
                    size="sm"
                    onChange={handleChange}
                    name="currency"
                    value={values.currency}
                    required
                    className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                  >
                    {currency.map((type, index) => {
                      return (
                        <option key={index} value={type} name="currency">
                          {type}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label style={{ fontSize: "14px" }} className="mb-3">
                    Tipo de cambio
                  </Form.Label>
                  <Form.Select
                    size="sm"
                    onChange={handleChange}
                    name="exchangeRate"
                    value={values.exchangeRate}
                    required
                    className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                  >
                    {exchangeRateList.map((rate, index) => {
                      return (
                        <option key={index} value={rate} name="exchangeRate">
                          {rate}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label style={{ fontSize: "14px" }} className="mb-3">
                    Porcentaje
                  </Form.Label>
                  <Form.Select
                    size="sm"
                    onChange={handleChange}
                    name="iva"
                    value={values.iva}
                    required
                    className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                  >
                    {taxPercentaje.map((percentaje, index) => {
                      return (
                        <option key={index} value={percentaje} name="iva">
                          {percentaje}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label style={{ fontSize: "14px" }} className="mb-3">
                    Aseguradoras
                  </Form.Label>
                  <Form.Select
                    size="sm"
                    onChange={handleChange}
                    name="insurer"
                    value={values.insurer}
                    required
                    className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                  >
                    <option value="" name="insurer" disabled>
                      Seleccione una opción
                    </option>
                    {insurerList.map((insurer) => {
                      return (
                        <option
                          key={insurer.insurerId}
                          value={insurer.label}
                          name="insurer"
                        >
                          {insurer.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            {isB2b && (
              <Grid container mb={2} mt={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <MUFormControl fullWidth size="small">
                    <Typography variant="body2">
                      {productToEdit
                        ? "Nombre Asociación"
                        : "Seleccionar Asociacion"}
                    </Typography>
                    {productToEdit ? (
                      // Show the association name if it's an edit operation
                      <Typography variant="body2">
                        {associationData?.name}
                      </Typography>
                    ) : (
                      // Show the selection dropdown if it's not an edit operation
                      <Select
                        value={values.selectedAssociation}
                        onChange={handleChange}
                        name="selectedAssociation"
                      >
                        {associations.map((association, index) => (
                          <MenuItem
                            key={index}
                            value={association}
                            label={association.name}
                          >
                            {association.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </MUFormControl>
                </Grid>
              </Grid>
            )}
            <Row className="mt-2">
              <Col>
                <TagsInput
                  value={tags}
                  onChange={setTags}
                  name="tags"
                  placeHolder="Introduzca nuevas etiquetas"
                  classNames={{
                    input:
                      "border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none",
                    tag: "bg-tags",
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col lg="1" style={{ width: "5%" }}>
            <div
              className="bg-secondary bg-opacity-25"
              style={{ width: "1px", height: "100%" }}
            ></div>
          </Col>
          <Col lg="3">
            <p className="text-muted mb-0 mt-3">Precio total</p>
            <h3 className="fw-bold mt-0">{finalPrice} CRC</h3>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default EditProduct;
