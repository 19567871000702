import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { Stack, Button, Container, Spinner } from "react-bootstrap";
import { Form } from "react-bootstrap";

import { v4 as uuid } from "uuid";

import EditProduct from "../../components/edit-product/edit-product";
import ProductAddition from "../../components/product-addition/product-addition";
import ProductValues from "../../components/product-values/product-values";

import { useParams } from "react-router-dom";

import { useFormik, FormikProvider } from "formik";

import darkLeftArrow from "./../../assests/darkLeftArrow.png";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  addAdminHistoryEntry,
  createProduct,
  deleteProduct,
  getInsurers,
  getProduct,
  getProductAdminHistory,
  updateProduct,
} from "../../firebase/firebase.utils";
import { fsTimestampCreador } from "../../firebase/firebase-config";
import { useDispatch, useSelector } from "react-redux";
import { getAllAssociations } from "../../redux/b2b/actions/actions";
import {
  getChangedFields,
  getDifferencesInFieldsData,
} from "../../utils/getChangeFields";
import AdminHistory from "../../components/adminHistory/AdminHistory";
import { translations } from "./spanishFieldName";
import { addInsurers } from "../../redux/insurers/actions/insurers";

const MySwal = withReactContent(Swal);

const AddProduct = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { associations } = useSelector((state) => state.b2b);
  const [insurers, setInsurers] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);
  const [productToEdit, setProductToEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [associationsFromDB, setAssociationsFromDB] = useState([]);
  const isFirstUse = useRef(true);

  const categories = ["Plan de seguros", "B2B"];
  const { user: currentAdmin } = useSelector((state) => state.auth);

  const currency = ["CRC"];
  const exchangeRateList = ["1.00", "2.00"];
  const apportionment = ["Si", "No"];
  const insurerList = insurers.map((insurerItem) => ({
    insurerId: insurerItem.id,
    label: insurerItem.name,
  }));
  const taxPercentaje = ["13%"];
  const descriptions = [
    "Gastos médicos por accidente",
    "Incapacidad permanente",
    "Muerte accidental",
    "SM asistencias",
    "Canjes y promociones",
  ];
  const descriptionValues = ["4500", "4500", "4200", "4800", "4400"];

  const data = [
    { description: "Gastos médicos por accidente", value: "4500" },
    { description: "Incapacidad permanente", value: "4500" },
    { description: "Muerte accidental", value: "4200" },
    { description: "SM asistencias", value: "4800" },
    { description: "Canjes y promociones", value: "4400" },
  ];

  const amounts = [2500, 4500];
  const variableNames = ["Membresia AllSports", "Plan de seguros bronce"];
  const variableRows = [
    { name: "Membresia AllSports", amount: 2500 },
    { name: "Plan de seguros bronce", amount: 4500 },
  ];

  const getAssociationsFromDB = useCallback(() => {
    try {
      dispatch(getAllAssociations());
    } catch (error) {
      console.log(error);
    }
  }, [associations]);

  const getAllInsurers = useCallback(async () => {
    setLoading(true);
    const insurerList = await getInsurers();

    setInsurers(insurerList);
    dispatch(addInsurers(insurerList));
  }, [dispatch, insurers]);

  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);

      getAssociationsFromDB();
      getAllInsurers();
      isFirstUse.current = false;
    } else if (associations.length > 0) {
      setLoading(false);
      setAssociationsFromDB(associations);
    }
  }, [getAssociationsFromDB, associations]);

  const initialValuesAddition = {
    productName: "",
    category: categories[0],
    currency: currency[0],
    ASPCode: "",
    CABYS: "",
    apportionment: apportionment[0],
    insurer: insurerList[0]?.name,
    exchangeRate: exchangeRateList[0],
    iva: taxPercentaje[0],
    variableNames: variableNames,
    amounts: amounts,
    variableData: descriptionValues,
    variableRows: variableRows,
    valuesData: descriptionValues,
    data: data,
    generalTags: [],
    selectedAssociation: "",
  };

  const initialValuesEdition = {
    productName: productToEdit?.subTier ? productToEdit.subTier : "",
    category: productToEdit?.category ? productToEdit.category : "",
    currency: productToEdit?.currency ? productToEdit.currency : "CRC",
    ASPCode: productToEdit?.ASPCode ? productToEdit.ASPCode : "",
    CABYS: productToEdit?.CABYS ? productToEdit.CABYS : "",
    apportionment: productToEdit?.apportionment === "Si" ? "Si" : "No",
    insurer: productToEdit?.insurer ? productToEdit.insurer : "",
    exchangeRate: productToEdit?.exchangeRate ? productToEdit.exchangeRate : "",
    iva: productToEdit?.iva ? productToEdit.iva + "%" : "",
    variableNames: variableNames ? variableNames : "",
    amounts: amounts ? amounts : "",
    variableData: descriptions ? descriptions : "",
    variableRows: productToEdit?.finalPriceLines
      ? productToEdit?.finalPriceLines
      : [],
    valuesData: descriptionValues ? descriptionValues : "",
    data: productToEdit?.data ? productToEdit.data : "",
    generalTags: productToEdit?.generalTags ? productToEdit?.generalTags : [],
    // selectedAssociation: productToEdit.codeName,
  };
  // console.log(initialValuesEdition);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: productToEdit ? initialValuesEdition : initialValuesAddition,
    // validationSchema: productSchema,
    onSubmit: async (values, { resetForm }) => {
      const confirmText = productToEdit
        ? "¿Estás seguro de que deseas editar este producto?"
        : "¿Estás seguro de que deseas agregar este producto?";

      const confirmResult = await MySwal.fire({
        title: "Confirmar",
        text: confirmText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, crear",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      const isB2b = values.category === "B2b";
      const selectedInsurer = insurerList.find(
        (insurerItem) => insurerItem.label === values.insurer
      );

      if (confirmResult.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });
        setLoading(true);
        const tags = selected;

        const productCode =
          values.productName.substring(0, 1) +
          "-" +
          Math.floor(Math.random() * 101);

        if (!id) {
          const data = values.data.map((_, index) => {
            return {
              description: values.data[index].description,
              value: values.data[index].value,
            };
          });

          const cdate = fsTimestampCreador.fromDate(new Date());

          const product = {
            code: isB2b ? values.selectedAssociation.codeName : productCode,
            cdate: cdate,
            category: values.category,
            currency: values.currency,
            ASPCode: values.ASPCode,
            CABYS: values.CABYS,
            apportionment: values.apportionment,
            insurer: values.insurer,
            insurerId: selectedInsurer.id,
            exchangeRate: values.exchangeRate,
            data: data,
            finalPrice: finalPrice,
            isActive: isB2b ? false : true,
            iva: Number(values.iva.replace("%", "")),
            subTier: values.productName,
            finalPriceLines: values.variableRows,
            generalTags: tags,
          };

          if (isB2b) {
            console.log("b2b", {
              isB2bActive: true,
              associationRef: values.selectedAssociation.id,
              ...product,
            });
            createProduct({
              isB2bActive: true,
              associationRef: values.selectedAssociation.id,
              ...product,
            });
          } else {
            createProduct(product);
          }

          setTimeout(() => {
            setLoading(false);
            callSuccessAction("Producto creado correctamente");
            resetForm();
          }, 2000);
        } else {
          const product = {
            //code: productCode,
            //cdate: cdate,
            category: values.category,
            currency: values.currency,
            ASPCode: values.ASPCode,
            CABYS: values.CABYS,
            apportionment: values.apportionment,
            insurer: values.insurer,
            insurerId: selectedInsurer.insurerId,
            exchangeRate: values.exchangeRate,
            data: values.data,
            finalPrice: finalPrice,
            finalPriceLines: values.variableRows,
            //isActive: true,
            iva: Number(values.iva.replace("%", "")),
            subTier: values.productName,
            generalTags: tags,
          };

          updateProduct(id, product);
          let fieldsChanged = getChangedFields(
            values,
            initialValuesEdition,
            translations
          );

          const differences = getDifferencesInFieldsData(fieldsChanged);

          const adminHistory = {
            modificationDate: new Date(),
            type: "products",
            admin: {
              id: currentAdmin.uid,
              name: currentAdmin.displayName,
              email: currentAdmin.email,
            },
            modifications: differences,
          };

          addAdminHistoryEntry(adminHistory, `products/${id}/adminHistory`);

          setTimeout(() => {
            setLoading(false);
            callSuccessAction("Producto actualizado correctamente");
            resetForm();
          }, 2000);
        }
      }
    },
  });

  useEffect(() => {
    const addFinalPrice = () => {
      const summary = formik.values.variableRows.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.amount;
        },
        0
      );

      setFinalPrice(summary);
    };

    addFinalPrice();
  }, [formik.values.variableRows]);

  useEffect(() => {
    const getSingleProduct = (id) => {
      getProduct(id).onSnapshot((product) => {
        const productData = product.data();
        let newLines;
        let tags = [];

        if (productData.finalPriceLines) {
          newLines = productData.finalPriceLines.map((line) => {
            return { ...line, onlyReadable: true, id: uuid().slice(0, 8) };
          });
        } else {
          newLines = [];
        }

        if (productData.generalTags) {
          setSelected(productData.generalTags);
        } else {
          setSelected(tags);
        }

        productData.finalPriceLines = newLines;
        setProductToEdit(productData);
      });

      //setFinalPrice(product.finalPrice);
      //setProductToEdit(product);
    };

    if (id) {
      getSingleProduct(id);
    }
  }, [id]);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary me-2",
      cancelButton: "btn btn-secondary",
      denyButton: "btn btn-danger me-2",
    },
    buttonsStyling: false,
  });

  const callOmitProduct = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Confirme su elección",
        showDenyButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Desactivar",
        confirmButtonColor: "#FAA60F",
        denyButtonText: "Eliminar",
      })
      .then((result) => {
        if (result.isConfirmed) {
          updateProduct(id, { isActive: false });
          Swal.fire("Producto desactivado", "", "success");
        } else if (result.isDenied) {
          deleteProduct(id);
          Swal.fire("Producto eliminado", "", "success");
        }
      });
  };

  const callIncludeProduct = () => {
    Swal.fire({
      title: "Confirme su elección",
      showDenyButton: true,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Activar",
      confirmButtonColor: "#FAA60F",
      denyButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        updateProduct(id, { isActive: true });
        Swal.fire("Producto activado", "", "success");
      } else if (result.isDenied) {
        deleteProduct(id);
        Swal.fire("Producto eliminado", "", "success");
      }
    });
  };

  const callSuccessAction = (message) => {
    Swal.fire(message, "", "success");
  };

  return (
    <Container>
      <FormikProvider value={formik}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
          noValidate
        >
          <Stack direction="horizontal" className="mb-3">
            <Link
              to="/products"
              className="w-25 d-block link-dark text-decoration-none"
            >
              <h1 className="h5 mb-0">
                <span className="d-inline-block" style={{ width: "14px" }}>
                  <img
                    src={darkLeftArrow}
                    alt="Black arrow icon"
                    className="w-100"
                  />
                </span>
                Atrás
              </h1>
            </Link>

            {id &&
              (productToEdit?.isActive ? (
                <Button
                  variant="link"
                  className="text-dark ms-auto"
                  onClick={callOmitProduct}
                >
                  Eliminar/Desactivar
                </Button>
              ) : (
                <Button
                  variant="link"
                  className="text-dark ms-auto"
                  onClick={callIncludeProduct}
                >
                  Eliminar/Activar
                </Button>
              ))}
          </Stack>
          {/* {id ? <EditProduct productId={id} /> : <ProductAddition />} */}

          <EditProduct
            finalPrice={finalPrice}
            productToEdit={productToEdit}
            id={id}
            tags={selected}
            setTags={setSelected}
            associations={associationsFromDB}
          />
          <ProductValues productToEdit={productToEdit} />
          <ProductAddition
            finalPriceLines={productToEdit?.finalPriceLines}
            productToEdit={productToEdit}
            id={id}
          />

          <div className="mt-4">
            <AdminHistory id={id} getAdminHistory={getProductAdminHistory} />
          </div>

          <Stack direction="horizontal" gap={3} className="mb-2 mt-3">
            <Link to="/commerce" className="ms-auto">
              <Button variant="info" className="rounded-pill">
                Cancelar
              </Button>
            </Link>
            <Button
              variant="primary"
              style={{ color: "white !important" }}
              className="rounded-pill"
              type="submit"
            >
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {!loading && (
                <span style={{ color: "white !important" }}>
                  {id ? "Guardar" : "Agregar"}
                </span>
              )}
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Container>
  );
};

export default AddProduct;
