import { types } from "../types/types";

const INITIAL_STATE = {
  plans: null,
};

const plansReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.addPlans:
      return {
        ...state,
        plans: action.payload,
      };
    case types.removePlans:
      return {
        ...state,
        plans: null,
      };
    default:
      return state;
  }
};

export default plansReducer;
