import { Button, Container, Typography } from "@mui/material";
import CommerceSummary from "../../components/commerce-summary/commerce-summary";
import InsurersTable from "../../components/commerce-table/insurersTable";
import AdminHistory from "../../components/adminHistory/AdminHistory";
import { getInsurerAdminHistory } from "../../firebase/firebase.utils";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PlansTableContainer from "../../components/plans/PlansTableContainer";
import { writeToFirestore } from "../../utils/globals";

const Plans = () => {
  const { insurers } = useSelector((state) => state.insurers);
  const [insurerIdList, setInsurerIdList] = useState([]);

  useEffect(() => {
    if (insurers?.length) {
      const idList = insurers.map((insurer) => insurer.id);

      setInsurerIdList(idList);
    }
  }, [insurers]);

  return (
    <Container maxWidth={"xl"}>
      <CommerceSummary />
      <PlansTableContainer />
      <div className="mt-4">
        <Typography variant="h5" component="h2" my={3}>
          Aseguradoras
        </Typography>
        <Button onClick={() => writeToFirestore("8Tb4AyhJw6OMiYN0eS90MAHAews1")}>

          aa
        </Button>
        <InsurersTable />

        <div className="mt-3">
          <AdminHistory
            id={insurerIdList}
            getAdminHistory={getInsurerAdminHistory}
          />
        </div>
      </div>
    </Container>
  );
};

export default Plans;
