import { useState } from "react";
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
  Checkbox,
  useTheme,
  Grid,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../redux/user/actions/auth";
import authErrors from "../../firebase/auth-error-codes";
import loginCover from "../../assests/logincover.png";
import logo from "../../assests/logo.png";

const LogInForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { error: authenticationError } = useSelector((state) => state.auth);

  const validationSchema = Yup.object({
    email: Yup.string().email("Email no valido").required("Email es requerido"),
    password: Yup.string()
      .min(6, "Contraseña debe contener al menos 6 caracteres")
      .required("Contraseña requerida"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(loginUser(values.email, values.password, formik.setFieldError));
    },
  });

  const navigateTo = (route) => navigate(route);

  return (
    <Container>
      <Grid container>
        {/* Login Cover Image */}
        <Grid item md={6}>
          <Box
            sx={{
              width: "100%",
              height: "auto",
              [theme.breakpoints.down("md")]: {
                margin: "1em auto 2em auto",
                width: "50%",
              },
            }}
          >
            <img
              src={loginCover}
              alt="Home"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Grid>

        {/* Login Form */}
        <Grid
          item
          md={6}
          xs={12}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <form
            onSubmit={formik.handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "65%",
            }}
          >
            {/* Logo */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
              }}
              mb={2}
            >
              <img
                src={logo}
                alt="Logo"
                style={{ width: "75%", height: "auto" }}
              />
            </Box>

            {/* Welcome Text */}
            <Box sx={{ width: "100%" }} mb={2}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Hola 👋
              </Typography>
              <Typography variant="body1">Por favor ingresa tus datos</Typography>
            </Box>

            {/* Email Field */}
            <FormControl sx={{ mb: 2 }} fullWidth>
              <TextField
                label="Email"
                type="email"
                id="email"
                variant="outlined"
                {...formik.getFieldProps("email")}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>

            {/* Password Field */}
            <FormControl sx={{ mb: 2 }} fullWidth>
              <TextField
                label="Password"
                type="password"
                id="password"
                variant="outlined"
                {...formik.getFieldProps("password")}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </FormControl>

            {/* Remember Me Checkbox */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    id="rememberMe"
                    checked={formik.values.rememberMe}
                    onChange={formik.handleChange}
                  />
                }
                label="Recordar Contraseña"
              />
              <Typography
                variant="body2"
                color={theme.palette.primary.main}
                sx={{ cursor: "pointer" }}
                onClick={() => navigateTo("/auth/forgotpassword")}
              >
                ¿Olvidaste tu contraseña?
              </Typography>
            </Box>

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 4 }}
            >
              Iniciar Sesión
            </Button>

            {/* Error Message */}
            {authenticationError && (
              <Typography
                variant="body2"
                color="error"
                sx={{ mt: 2, textAlign: "center" }}
              >
                {authErrors[authenticationError?.code]?.msg ||
                  "Error desconocido. Intenta nuevamente."}
              </Typography>
            )}
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LogInForm;
