import { db } from "../firebase/firebase-config";

export const ADMIN_API_URL = `https://us-central1-allsports-69231.cloudfunctions.net/admins`;
export const API_URL = `https://us-central1-allsports-69231.cloudfunctions.net`;



export function writeToFirestore(uid) {
    const planType = {
      amount: 1400000,
      badgeImg: "https://firebasestorage.googleapis.com/v0/b/allsports-69231.appspot.com/o/plans%2Fbadges%2FMedalla-Bronce.png?alt=media&token=ea0ba07c-dc25-45ef-a9a8-a9b26dde0cb0",
    //   cdate: firebase.firestore.Timestamp.fromDate(new Date("September 4, 2024 00:00:00 UTC-6")),
      code: "INS-01",
      data: [
        "Gastos Médicos Por Accidente",
        "Muerte Accidental",
        "Incapacidad"
      ],
      deductible: false,
      finalPrice: 2750,
      insurerId: "7ent45On7hnaVfXAZx15",
      isActive: true,
      isElderPlan: false,
      name: "Bronce",
      price: 2750,
      price2: 9610,
      price3: 23470,
      refundPercentage: "100%"
    };
  
    // Update the user's document with the new planType field
    db.collection("users").doc(uid).update({
      planType: planType
    })
    .then(() => {
      console.log("Plan type updated successfully for user ID: ", uid);
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
  }