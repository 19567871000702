import { FormikProvider, useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { Button, Container, Form, Spinner, Stack } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import darkLeftArrow from "./../../assests/darkLeftArrow.png";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  addAdminHistoryEntry,
  createBadgeImage,
  createPlan,
  deletePlan,
  getInsurers,
  getOnePlan,
  getPlanAdminHistory,
  getProductAdminHistory,
  updatePlan,
} from "../../firebase/firebase.utils";
import { addInsurers } from "../../redux/insurers/actions/insurers";
import { useDispatch, useSelector } from "react-redux";
import { fsTimestampCreador } from "../../firebase/firebase-config";
import {
  getChangedFields,
  getDifferencesInFieldsData,
} from "../../utils/getChangeFields";
import { translations } from "./spanishFieldName";
import EditPlan from "../../components/editPlan/EditPlan";
import EditPlanData from "../../components/editPlan/EditPlanData";
import UploadImage from "../../components/general/UploadImage";
import { Box, Grid } from "@mui/material";
import { planSchema } from "../../schemas/plan/planSchema";
import AdminHistory from "../../components/adminHistory/AdminHistory";

const MySwal = withReactContent(Swal);

const data = [
  "Gastos Médicos por Accidente",
  "Muerte Accidental",
  "Incapacidad",
];

const AddPlan = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user: currentAdmin } = useSelector((state) => state.auth);

  const [imageUpload, setImageUpload] = useState([]);
  const [image, setImage] = useState([]);

  const [loading, setLoading] = useState(false);
  const [planToEdit, setPlanToEdit] = useState(null);
  const [insurers, setInsurers] = useState([]);
  const [insurerList, setInsurerList] = useState([]);

  const getAllInsurers = useCallback(async () => {
    setLoading(true);
    const insurerList = await getInsurers();

    setInsurers(insurerList);
    dispatch(addInsurers(insurerList));
    setLoading(false);
  }, [dispatch, insurers]);

  useEffect(() => {
    getAllInsurers();
  }, []);

  useEffect(() => {
    const insurerList = insurers.map((insurerItem) => ({
      insurerId: insurerItem.id,
      label: insurerItem.name,
    }));

    setInsurerList(insurerList);
  }, [insurers]);

  useEffect(() => {
    const getSinglePlan = async () => {
      const plan = await getOnePlan(id);

      setPlanToEdit(plan);

      if (plan.badgeImg) {
        setImage([plan.badgeImg]);
      }
    };

    if (id) {
      getSinglePlan();
    }
  }, [id]);

  const initialValuesAddition = {
    name: "",
    deductible: false,
    insurer: "",
    isActive: true,
    code: "",
    amount: 0,
    price: 0,
    price2: 0,
    price3: 0,
    refundPercentage: "",
    data: data,
    badgeImg: "",
  };

  const initialValuesEdition = {
    name: planToEdit?.name ? planToEdit.name : "",
    deductible: planToEdit?.deductible ? planToEdit?.deductible : false,
    insurer: planToEdit?.insurer ? planToEdit.insurer : "",
    isActive: planToEdit?.isActive ? planToEdit?.isActive : true,
    code: planToEdit?.code ? planToEdit?.code : "",
    amount: planToEdit?.amount ? planToEdit?.amount : 0,
    price: planToEdit?.price ? planToEdit?.price : 0,
    price2: planToEdit?.price2 ? planToEdit?.price2 : 0,
    price3: planToEdit?.price3 ? planToEdit?.price3 : 0,
    data: planToEdit?.data ? planToEdit?.data : 0,
    refundPercentage: planToEdit?.refundPercentage
      ? parseFloat(planToEdit?.refundPercentage.replace("%", ""))
      : "",
    badgeImg: planToEdit?.badgeImg ? planToEdit.badgeImg : "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: planToEdit ? initialValuesEdition : initialValuesAddition,
    validationSchema: planSchema,
    onSubmit: async (values, { resetForm }) => {
      const confirmText = planToEdit
        ? "¿Estás seguro de que deseas editar este plan?"
        : "¿Estás seguro de que deseas agregar este plan?";

      const confirmResult = await MySwal.fire({
        title: "Confirmar",
        text: confirmText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, crear",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      const isB2b = values.category === "B2b";
      const selectedInsurer = insurers.find(
        (insurerItem) => insurerItem.name === values.insurer
      );

      const today = new Date();

      const plan = {
        name: values.name,
        deductible: values.deductible,
        insurer: values.insurer,
        isActive: true,
        code: values.code,
        amount: values.amount,
        price: values.price,
        price2: values.price2,
        price3: values.price3,
        refundPercentage: values.refundPercentage + "%",
        data: values.data,
        cdate: today,
        insurerId: selectedInsurer.id,
      };

      if (confirmResult.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });
        setLoading(true);
        // const tags = selected;

        // const productCode =
        //   values.productName.substring(0, 1) +
        //   "-" +
        //   Math.floor(Math.random() * 101);

        if (!id) {
          const data = values.data.map((_, index) => {
            return {
              description: values.data[index].description,
              value: values.data[index].value,
            };
          });

          // if (isB2b) {
          //   console.log("b2b", {
          //     isB2bActive: true,
          //     associationRef: values.selectedAssociation.id,
          //     ...product,
          //   });
          //   createProduct({
          //     isB2bActive: true,
          //     associationRef: values.selectedAssociation.id,
          //     ...product,
          //   });
          // } else {
          //   createProduct(product);
          // }

          const newPlanId = await createPlan(plan);

          if (newPlanId && imageUpload.length) {
            createBadgeImage(imageUpload, newPlanId);
          }

          setTimeout(() => {
            setLoading(false);
            callSuccessAction("Plan creado correctamente");
            resetForm();
          }, 2000);
        } else {
          const plan = {
            name: values.name,
            deductible: values.deductible,
            insurer: values.insurer,
            code: values.code,
            amount: values.amount,
            price: values.price,
            price2: values.price2,
            price3: values.price3,
            refundPercentage: values.refundPercentage + "%",
            data: values.data,
            cdate: today,
            insurerId: selectedInsurer.id,
          };

          updatePlan(id, plan);

          let fieldsChanged = getChangedFields(
            values,
            initialValuesEdition,
            translations
          );

          if (imageUpload.length) {
            createBadgeImage(imageUpload, id);
          }

          const differences = getDifferencesInFieldsData(fieldsChanged);

          const adminHistory = {
            modificationDate: new Date(),
            type: "plans",
            admin: {
              id: currentAdmin.uid,
              name: currentAdmin.displayName,
              email: currentAdmin.email,
            },
            modifications: differences,
          };

          console.log(adminHistory);

          addAdminHistoryEntry(adminHistory, `plans/${id}/adminHistory`);

          setTimeout(() => {
            setLoading(false);
            callSuccessAction("Plan actualizado correctamente");
            resetForm();
          }, 2000);
        }
      }

      navigate("/plans");
    },
  });

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary me-2",
      cancelButton: "btn btn-secondary",
      denyButton: "btn btn-danger me-2",
    },
    buttonsStyling: false,
  });

  const callOmitProduct = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Confirme su elección",
        showDenyButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Desactivar",
        confirmButtonColor: "#FAA60F",
        denyButtonText: "Eliminar",
      })
      .then((result) => {
        if (result.isConfirmed) {
          updatePlan(id, { isActive: false });
          Swal.fire("Plan desactivado", "", "success");
        } else if (result.isDenied) {
          deletePlan(id);
          Swal.fire("Plan eliminado", "", "success");
        }
      });
  };

  const callIncludeProduct = () => {
    Swal.fire({
      title: "Confirme su elección",
      showDenyButton: true,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Activar",
      confirmButtonColor: "#FAA60F",
      denyButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        updatePlan(id, { isActive: true });
        Swal.fire("Plan activado", "", "success");
      } else if (result.isDenied) {
        deletePlan(id);
        Swal.fire("Plan eliminado", "", "success");
      }
    });
  };

  const callSuccessAction = (message) => {
    Swal.fire(message, "", "success");
  };

  return (
    <Container>
      <FormikProvider value={formik}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
          noValidate
        >
          <Stack direction="horizontal" className="mb-3">
            <Link
              to="/plans"
              className="w-25 d-block link-dark text-decoration-none"
            >
              <h1 className="h5 mb-0">
                <span className="d-inline-block" style={{ width: "14px" }}>
                  <img
                    src={darkLeftArrow}
                    alt="Black arrow icon"
                    className="w-100"
                  />
                </span>
                Atrás
              </h1>
            </Link>

            {id &&
              (planToEdit?.isActive ? (
                <Button
                  variant="link"
                  className="text-dark ms-auto"
                  onClick={callOmitProduct}
                >
                  Eliminar/Desactivar
                </Button>
              ) : (
                <Button
                  variant="link"
                  className="text-dark ms-auto"
                  onClick={callIncludeProduct}
                >
                  Eliminar/Activar
                </Button>
              ))}
          </Stack>

          <EditPlan planToEdit={planToEdit} id={id} />

          <EditPlanData data={data} />

          <div className="mt-4">
            <AdminHistory id={id} getAdminHistory={getPlanAdminHistory} />
          </div>

          <Box my={3}>
            <p style={{ textAlign: "center" }}>Insiginia</p>
            <Grid container justifyContent="center">
              <Grid item>
                <UploadImage
                  setImageUpload={setImageUpload}
                  setImage={setImage}
                  image={image}
                />
              </Grid>
            </Grid>
          </Box>

          <Stack direction="horizontal" gap={3} className="mb-2 mt-3">
            <Link to="/commerce" className="ms-auto">
              <Button variant="info" className="rounded-pill">
                Cancelar
              </Button>
            </Link>
            <Button
              variant="primary"
              style={{ color: "white !important" }}
              className="rounded-pill"
              type="submit"
            >
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {!loading && (
                <span style={{ color: "white !important" }}>
                  {id ? "Guardar" : "Agregar"}
                </span>
              )}
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Container>
  );
};

export default AddPlan;
