export const translations = {
  planName: "Nombre del plan",
  deductible: "Deducible",
  insurer: "Aseguradora",
  isActive: "Estado",
  code: "Codigo",
  amount: "Monto asegurado",
  price: "Precio",
  price2: "Precio +65",
  price3: "Precio +75",
  refundPercentage: "Porcentaje de reembolso",
  //variableNames: variableNames,
  //amounts: amounts,
  //variableData: descriptionValues,
  //variableRows: variableRows,
  //valuesData: descriptionValues,
  //data: data,
  //generalTags: [],
  //selectedAssociation: "",
};
