import { Card, Form } from "react-bootstrap";

import FormInput from "../form-input/form-input";
import { useFormikContext } from "formik";

const EditPlanData = ({ data }) => {
  const { errors, handleChange, values, handleBlur, touched } =
    useFormikContext();

  return (
    <Card border="light" className="mt-4">
      <Card.Body>
        {data.map((_, index) => {
          return (
            <Form.Group>
              <FormInput
                label={"Ingrese un valor"}
                onChange={handleChange}
                value={values.data[index]}
                type="text"
                name={`data[${index}]`}
                errorList={errors}
                onBlur={handleBlur}
                touched={touched}
              />

              <Form.Control.Feedback type="invalid">
                {errors.data ? errors.data[index] : null}
              </Form.Control.Feedback>
            </Form.Group>
          );
        })}
      </Card.Body>
    </Card>
  );
};

export default EditPlanData;
