import { ListGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { fsTimestampCreador } from "../../firebase/firebase-config";

const listItemStyles = {
  fontSize: "13px",
  border: "none",
};

const HistoryEntryDetails = ({ historyEntry }) => {
  const formatValue = (value) => {
    const dateValue =
      value instanceof fsTimestampCreador ? value.toDate() : false;
    if (dateValue) {
      return dateValue.toLocaleDateString("es-CR", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
      });
    }

    if (Array.isArray(value)) {
      return value.join(", ");
    }

    if (typeof value === "boolean") {
      return value ? "Si" : "No";
    }

    const statusMap = {
      pending: "Pendiente",
      rejected: "Rechazado",
      accepted: "Aceptado",
    };

    return statusMap[value] || value;
  };

  //This fields are consider as value images
  const imageFields = {
    imageURLs: true,
    logo: true,
  };

  const getFieldMessage = (modification) => {
    const { field, fieldEs, oldValue, newValue } = modification;

    if (imageFields[field]) {
      return (
        <>
          <ListGroup.Item
            as="li"
            disabled
            style={listItemStyles}
            className="mb-0"
          >
            El campo <strong>{fieldEs ?? field}</strong> ha cambiado
          </ListGroup.Item>
        </>
      );
    }

    return (
      <>
        <ListGroup.Item
          as="li"
          disabled
          style={listItemStyles}
          className="mb-0"
        >
          El campo <strong>{fieldEs ?? field}</strong>
          {oldValue === "" ? (
            " no tenía valor"
          ) : (
            <span>
              {" "}
              era <strong>{formatValue(oldValue)}</strong>
            </span>
          )}
          , ahora es{" "}
          <strong>{newValue === "" ? "vacío" : formatValue(newValue)}</strong>
        </ListGroup.Item>
      </>
    );
  };

  const getObjFieldMessage = (modification, variableName, variableValue) => {
    const { oldValue, newValue } = modification;
    const maxLength = Math.max(oldValue.length, newValue.length);

    return (
      <>
        {Array.from({ length: maxLength }).map((_, index) => {
          const oldObj = oldValue[index];
          const newObj = newValue[index];

          let message;
          if (oldObj && newObj) {
            message = (
              <>
                La variable <strong>{oldObj[variableName]}</strong> con el valor{" "}
                <strong>{oldObj[variableValue]}</strong>, ahora es{" "}
                <strong>{newObj[variableName]}</strong> con el valor{" "}
                <strong>{newObj[variableValue]}</strong>
              </>
            );
          } else if (oldObj) {
            message = (
              <>
                La variable <strong>{oldObj[variableName]}</strong> con el valor{" "}
                <strong>{oldObj[variableValue]}</strong>, ha sido eliminada
              </>
            );
          } else if (newObj) {
            message = (
              <>
                La variable <strong>{newObj[variableName]}</strong> con el valor{" "}
                <strong>{newObj[variableValue]}</strong>, ha sido agregada
              </>
            );
          } else {
            message = null;
          }

          return (
            <ListGroup.Item
              as="li"
              disabled
              key={index}
              style={listItemStyles}
              className="mb-0"
            >
              {message}
            </ListGroup.Item>
          );
        })}
      </>
    );
  };

  return (
    <Form.Group className="mb-5">
      <ListGroup className="mb-3">
        <ListGroup.Item
          as="li"
          disabled
          style={{ fontSize: "16px", border: "none" }}
          className="mb-0"
        >
          <strong>
            {historyEntry.admin.name
              ? historyEntry.admin.name
              : historyEntry.admin.email}{" "}
            ejecutó los siguientes cambios:
          </strong>
        </ListGroup.Item>

        {historyEntry.modifications.map((modification) => {
          const { newValue, oldValue } = modification;
          let selectedArray = null;

          if (oldValue.length > newValue.length) {
            selectedArray = oldValue;
          } else {
            selectedArray = newValue;
          }

          //Some fields from products can be objects
          if (historyEntry.type !== "products") {
            //It wasn't a products field
            return getFieldMessage(modification);
          } else if (
            Array.isArray(selectedArray) &&
            selectedArray[0]?.description
          ) {
            //It was a data field
            return getObjFieldMessage(modification, "description", "value");
          } else if (Array.isArray(selectedArray) && selectedArray[0]?.name) {
            //It wasn't a variableRows field
            return getObjFieldMessage(modification, "name", "amount");
          } else {
            //The field from products wasn't an object
            return getFieldMessage(modification);
          }
        })}

        <ListGroup.Item as="li" disabled style={listItemStyles}>
          Fecha de modificación:{" "}
          <strong>
            {historyEntry.modificationDate
              .toDate()
              .toLocaleDateString("es-CR", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
              })}
          </strong>
        </ListGroup.Item>

        <ListGroup.Item
          as="li"
          disabled
          style={listItemStyles}
          className="mb-0"
        >
          {historyEntry?.observations
            ? `Observaciones: ${historyEntry.observations}`
            : ""}
        </ListGroup.Item>
      </ListGroup>
    </Form.Group>
  );
};

export default HistoryEntryDetails;
