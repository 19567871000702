import { types } from "../types/types";

export const getPlans = () => ({
  type: types.plans,
});

export const deletePlans = () => ({
  type: types.removePlans,
});

export const addPlans = (plans) => ({
  type: types.addPlans,
  payload: plans,
});
