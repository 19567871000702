import { db, storageRef } from "../../../firebase/firebase-config";
import { types } from "../types/types";

export const getInsurers = () => ({
  type: types.insurers,
});

export const deleteInsurers = () => ({
  type: types.removeInsurers,
});

export const addInsurers = (insurers) => ({
  type: types.addInsurers,
  payload: insurers,
});

export const getAllInsurers = async () => {
  try {
    const insurersList = [];
    const result = await db.collection("insurers").get();

    result.docs.forEach((doc) => {
      insurersList.push({ id: doc.id, ...doc.data() });
    });

    return insurersList;
  } catch (error) {
    console.log(error);
  }
};

export const addInsurer = async (insurerData) => {
  try {
    await db.collection("insurers").add(insurerData);
  } catch (error) {
    console.log(error);
  }
};

export const updateInsurer = async (insurerData, insurerId, imageUpload) => {
  try {
    let newData = insurerData;
    console.log(imageUpload);

    if (imageUpload[0]) {
      const logoUrl = await createInsurerLogo(imageUpload[0], insurerId);
      console.log(logoUrl);
      newData = { ...insurerData, logo: logoUrl };
    } else {
      const logoUrl = "";
      newData = { ...insurerData, logo: logoUrl };
    }

    console.log(newData);
    await db.collection("insurers").doc(insurerId).update(newData);

    return newData;
  } catch (error) {
    console.error(error);
  }
};

export const uploadFile = async (path, file) => {
  if (!(file instanceof File)) return;

  try {
    const upload = await storageRef.ref(path).put(file);
    const fileUrl = await upload.ref.getDownloadURL();

    return fileUrl;
  } catch (error) {
    console.log(file, path);
    console.log("No se puede subir el archivo seleccionado");
  }
};

export const createInsurerLogo = async (imageUpload, insurerId) => {
  try {
    const path = `insurers/${insurerId}/logo`;
    const fileUrl = await uploadFile(path, imageUpload);

    return fileUrl;
  } catch (error) {
    console.log(error);
  }
};
