import { useState, useRef, useEffect } from "react";

import { Button } from "react-bootstrap";
import { Stack } from "react-bootstrap";

import BriefCard from "../../components/brief-card/brief-card";
// import SponsorsTable from "../../components/sponsors-table/sponsors-table";

import CustomModal from "../../components/modal/customModal";
import SponsorAddition from "../../components/sponsor-addition/sponsor-addition";
import { getSponsorLocations } from "../../firebase/firebase.utils";
import { SponsorsTable } from "../../components/sponsors/SponsorsTable";
import { Container } from "@mui/material";
import { Typography } from "@mui/material";
import { styles } from "../../styles/styles";
import { RequestsTable } from "../../components/sponsors/RequestsTable";

const SponsorRequests = () => {
  const [showSponsorEditionModal, setShowSponsorEditionModal] = useState(false);
  const [count, setCount] = useState(0);
  const [locations, setLocations] = useState([]);
  // const handleClick = () => {
  //   setCount((current) => current + 1);
  // };

  const handleRefresh = () => {
    setCount((current) => current + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sponsorLocations = await getSponsorLocations();
        setLocations(sponsorLocations);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleCopy = () => {
    const locationsJSON = JSON.stringify(locations);

    navigator.clipboard
      .writeText(locationsJSON)
      .then(() => {
        console.log("JSON list copied to clipboard");
      })
      .catch((error) => {
        console.log("Error copying JSON list to clipboard:", error);
      });
  };
  return (
    <Container maxWidth={"xl"}>
      <Stack direction="horizontal" gap={2} className="mb-4">
        <Typography variant="h5" sx={styles.title}>
          Solicitudes de Sponsor
        </Typography>
        {/* <button onClick={handleCopy}>Copy JSON</button> */}

        {/* <Button className="ms-auto rounded-pill" onClick={handleClick}>
          Recargar
        </Button> */}
      </Stack>
      <BriefCard />
      {/* <SponsorsTable count={count}/> */}
      <RequestsTable />
      <CustomModal
        show={showSponsorEditionModal}
        handleClose={setShowSponsorEditionModal}
        currentSize={"lg"}
        dialogClassName={"sponsorAddModal"}
        modalTitle={() => <HeaderButtons type={"promo"} />}
      >
        <SponsorAddition
          handleRefresh={handleRefresh}
          onClose={setShowSponsorEditionModal}
        />
      </CustomModal>
    </Container>
  );
};

export default SponsorRequests;

const HeaderButtons = ({ type }) => (
  <Stack direction="horizontal" gap={2} className="">
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#9A9A9A" }}
    >
      Agregar
    </Button>
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#AAAAAA" }}
    >
      Sponsor
    </Button>
  </Stack>
);
