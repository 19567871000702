import { useState, useEffect, useMemo, useCallback } from "react";

import { ReactComponent as DarkPencilIcon } from "./../../assests/pencil.svg";
import { ReactComponent as DarkTrashCanIcon } from "./../../assests/trashcan.svg";
import { ReactComponent as DarkRoundedMenuIcon } from "./../../assests/rounded-hamburger-menu.svg";

import { Table, Spinner } from "react-bootstrap";
import { Stack } from "react-bootstrap";

import Swal from "sweetalert2";

import ReactPaginate from "react-paginate";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import TableHeader from "../table/table-header";
import TableBodyHover from "../table/table-body-hover";
import ItemsControl from "../items-control/items-control";
import TableFilters from "../table/table-filters";
import CoinLoader from "./../coin-loader/coin-loader";

import {
  deleteProduct,
  getProducts,
  updateProduct,
} from "../../firebase/firebase.utils";

import "./products-table.scss";

import { searchTable } from "../../custom hooks/search-table/search-table";
import { sortProductsTable } from "../../custom hooks/sort-table/sort-products-table";
import { useDispatch, useSelector } from "react-redux";
import { addProducts } from "../../redux/products/actions/actions";

import { useNavigate } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";

const ProductsTable = ({
  setSearchField,
  dropdownList,
  options,
  getAllProducts,
  loading,
  pageCount,
  rows,
  headers,
  handlePageClick,
  setItemsPerPage,
}) => {
  const matchesUp1200 = useMediaQuery("(min-width:1200px)");
  const matchesDown620 = useMediaQuery("(max-width:620px)");

  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const reduxProducts = useSelector((state) => state.products);

  // const [products, setProducts] = useState([]);
  // const [filteredProducts, setFilteredProducts] = useState([]);
  // const [loading, setLoading] = useState(false);

  // const headers = [
  //   { name: "Nombre", accessor: "planName" },
  //   { name: "Moneda", accessor: "currency" },
  //   { name: "Precio", accessor: "price" },
  //   { name: "IVA", accessor: "tax" },
  //   { name: "Aseguradora", accessor: "insurer" },
  //   { name: "Total", accessor: "total" },
  //   { name: "Prorrateo", accessor: "apportionment" },
  //   { name: "En la app", accessor: "status" },
  //   { name: "Acciones", accessor: "actions" },
  // ];
  // const headersSerch = [
  //   { name: "Nombre", accessor: "planName" },
  //   { name: "Moneda", accessor: "currency" },
  //   { name: "Precio", accessor: "price" },
  //   { name: "IVA", accessor: "tax" },
  //   { name: "Aseguradora", accessor: "insurer" },
  //   { name: "Total", accessor: "total" },
  //   { name: "Prorrateo", accessor: "apportionment" },
  //   { name: "MO", accessor: "status" },
  // ];

  // const handleClick = (url) => {
  //   navigate(url);
  // };

  // const getPrice = (finalPrice) => {
  //   const price = finalPrice / 1.13;

  //   return price.toFixed(2);
  // };

  // const swalWithBootstrapButtons = Swal.mixin({
  //   customClass: {
  //     confirmButton: "btn btn-danger me-2",
  //     cancelButton: "btn btn-secondary",
  //   },
  //   buttonsStyling: false,
  // });

  // const callDeleteProduct = (id) => {
  //   swalWithBootstrapButtons
  //     .fire({
  //       title: "¿Desea eliminar este producto?",
  //       showCancelButton: true,
  //       cancelButtonText: "Cancelar",
  //       confirmButtonText: "Eliminar",
  //     })
  //     .then((result) => {
  //       if (result.isConfirmed) {
  //         deleteProduct(id);
  //         Swal.fire("Producto eliminado", "", "success");
  //         getAllProducts();
  //       }
  //     });
  // };

  // const newProductsList = useMemo(
  //   () =>
  //     filteredProducts.map((product) => {
  //       return {
  //         id: product.id,
  //         planName: product.subTier ? product.subTier : "--",
  //         currency: product.currency ? product.currency : "--",
  //         price: product.finalPrice ? getPrice(product.finalPrice) : 0,
  //         tax: product.iva ? (
  //           <span className="text-active">{product.iva + "%"}</span>
  //         ) : (
  //           <span className="text-active">13%</span>
  //         ),
  //         taxAmount: product.finalPrice
  //           ? (product.finalPrice - getPrice(product.finalPrice)).toFixed(2)
  //           : 0,
  //         total: product.finalPrice ? product.finalPrice : 0,
  //         apportionment: product.apportionment === "Si" ? "Si" : "No",
  //         status: product.isActive ? (
  //           <span className="text-active">Mostrado</span>
  //         ) : (
  //           <span className="text-danger">No Mostrado</span>
  //         ),
  //         actions: (
  //           <Stack direction="horizontal" gap={2} key={product.id}>
  //             <span variant="secondary" size="sm" className="text-light">
  //               <DarkPencilIcon
  //                 onClick={() => handleClick(`edit-product/${product.id}`)}
  //               />
  //             </span>
  //             <span variant="secondary" size="sm" className="text-light">
  //               <DarkTrashCanIcon
  //                 onClick={() => callDeleteProduct(product.id)}
  //               />
  //             </span>
  //             <span variant="secondary" size="sm" className="text-light">
  //               <DarkRoundedMenuIcon />
  //             </span>
  //           </Stack>
  //         ),
  //       };
  //     }),
  //   [filteredProducts]
  // );

  // const [itemsPerPage, setItemsPerPage] = useState(5);
  // //const [filteringBy, setfilteringBy] = useState("Name");

  // // We start with an empty list of items.
  // const [currentItems, setCurrentItems] = useState([]);
  // const [pageCount, setPageCount] = useState(0);
  // // Here we use item offsets; we could also use page offsets
  // // following the API or data you're working with.
  // const [itemOffset, setItemOffset] = useState(0);

  // useEffect(() => {
  //   // Fetch items from another resources.
  //   const endOffset = itemOffset + Number(itemsPerPage);
  //   // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  //   setCurrentItems(newProductsList.slice(itemOffset, endOffset));
  //   setPageCount(Math.ceil(newProductsList.length / Number(itemsPerPage)));
  // }, [itemOffset, newProductsList, itemsPerPage]);

  // const getAllProducts = useCallback(async () => {
  //   setLoading(true);
  //   const productsList = await getProducts();

  //   setProducts(productsList);
  //   setFilteredProducts(products);
  //   dispatch(addProducts(productsList));
  // }, [dispatch, products]);

  // useEffect(() => {
  //   setLoading(true);
  //   if (reduxProducts.products) {
  //     console.log("No nuevos productos");
  //     const { products } = reduxProducts;
  //     setProducts(products);
  //     setFilteredProducts(products);
  //     setLoading(false);
  //   } else {
  //     getAllProducts();
  //   }
  // }, [reduxProducts]);

  // // Invoke when user click to request another page.
  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % newProductsList.length;
  //   console.log(
  //     `User requested page number ${event.selected}, which is offset ${newOffset}`
  //   );
  //   setItemOffset(newOffset);
  // };

  // const sortTableElements = (sortingMethod) => {
  //   const sortedItems = sortProductsTable(currentItems, sortingMethod);
  //   setCurrentItems(sortedItems);
  // };

  // const dropdownList = [
  //   {
  //     valuesList: [
  //       { name: "IVA + a -" },
  //       { name: "IVA - a +" },
  //       { name: "Precio + a -" },
  //       { name: "Precio - a +" },
  //     ],
  //     label: "",
  //     filterMethod: sortTableElements,
  //   },
  // ];

  // const showActiveProducts = (list) => {
  //   const activeProducts = list.filter((product) => product.isActive);

  //   setFilteredProducts(activeProducts);
  // };

  // const showInactiveProducts = (list) => {
  //   const inactiveProducts = list.filter((product) => !product.isActive);

  //   setFilteredProducts(inactiveProducts);
  // };

  // const showAllProducts = (list) => {
  //   setFilteredProducts(list);
  // };

  // const options = [
  //   { text: "Todos", filterMethod: () => showAllProducts(products) },
  //   { text: "Activos", filterMethod: () => showActiveProducts(products) },
  //   { text: "Inactivos", filterMethod: () => showInactiveProducts(products) },
  // ];

  // const [searchField, setSearchField] = useState("");
  // const rows = searchTable(currentItems, headersSerch, searchField);

  return (
    <>
      <TableFilters
        options={options}
        dropdowns={matchesUp1200 ? dropdownList : null}
        searchRows={matchesUp1200 ? setSearchField : null}
        handleRefresh={matchesUp1200 ? () => getAllProducts() : null}
        showArrows={matchesDown620}
        product={true}
      />

      <div className="p-4 bg-light">
        {loading ? (
          <div className="loading-container">
            <CoinLoader size={{ width: 150, height: 150 }} />
          </div>
        ) : (
          <Table
            className="bg-light mb-0 p-4"
            responsive
            style={{ width: "400px !important" }}
          >
            <TableHeader headers={headers} />
            <TableBodyHover
              //rows={currentItems}
              rows={rows}
              columns={headers}
              clickGoTo="/user/"
            />
          </Table>
        )}
      </div>

      <div className="bg-light p-4 rounded-bottom">
        <Stack direction="horizontal">
          <ItemsControl setNumItemPerPage={setItemsPerPage} />

          <div className="ms-auto">
            <ReactPaginate
              breakLabel="..."
              nextLabel={<IoIosArrowForward />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<IoIosArrowBack />}
              renderOnZeroPageCount={null}
              containerClassName="pagination pagination-modifier"
              pageClassName="page-item"
              previousClassName="previus-button"
              nextClassName="next-button"
              pageLinkClassName="page-link page-link-modifier"
              activeClassName="active"
            />
          </div>
        </Stack>
      </div>
    </>
  );
};

export default ProductsTable;
