import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { authReducer } from "./user/reducers/authReducer";
import usersReducer from "./users/reducer/usersReducer";
import achievementReducer from "./achievements/reducer/achievementsReducer";
import { requestsReducer } from "./requests/reducer/requestsReducer";
import causesReducer from "./causes/reducer/causesReducer";
import sponsorsReducer from "./sponsors/reducer/sponsorsReducer";
import membersReducer from "./members/reducer/membersReducer";
import productsReducer from "./products/reducer/productsReducer";
import customerReducer from "./customers/reducer/customerReducer";
import bannersReducer from "./banners/reducer/bannersReducer";
import b2bReducer from "./b2b/reducer/b2bReducer";
import { blogsReducer } from "./blogs/blogsReducer";
import insurersReducer from "./insurers/reducers/insurersReducer";
import plansReducer from "./plan/reducer/plansReducer";

export const store = configureStore(
  {
    middleware: [thunk],
    reducer: {
      auth: authReducer,
      user: usersReducer,
      achievement: achievementReducer,
      requests: requestsReducer,
      causes: causesReducer,
      businesses: sponsorsReducer,
      members: membersReducer,
      products: productsReducer,
      customers: customerReducer,
      banners: bannersReducer,
      b2b: b2bReducer,
      blogs: blogsReducer,
      insurers: insurersReducer,
      plans: plansReducer,
    },
  },

  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
