import React, { useMemo, useCallback, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Box, Typography, useMediaQuery, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { IoRefreshOutline } from "react-icons/io5";
import { Stack } from "react-bootstrap";
import { addPlans } from "../../redux/plan/actions/actions";
import { getPlans, deletePlan } from "../../firebase/firebase.utils";
import { MdArrowOutward } from "react-icons/md";
import CustomBodyWithSpinner from "../general/CustomBodyWithSpinner";

const PlansTableContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxPlans = useSelector((state) => state.plans);
  const { insurers } = useSelector((state) => state.insurers);
  const matchesUp1200 = useMediaQuery("(min-width:1200px)");

  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const getInsurerName = (insurerCode) => {
    if (!insurers) return "--";
    const insurerData = insurers.find(
      (insurer) => insurer.code === insurerCode
    );

    return insurerData ? insurerData.name : "--";
  };

  const getPriceFromRange = (plan, index) => {
    return plan.pricesByAgeRange && plan.pricesByAgeRange[index]
      ? plan.pricesByAgeRange[index].price
      : 0;
  };

  const callDeletePlan = (id) => {
    Swal.fire({
      title: "¿Desea eliminar este plan?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        deletePlan(id);
        Swal.fire("Plan eliminado", "", "success");
        getAllPlans();
      }
    });
  };

  const getAllPlans = useCallback(async () => {
    setLoading(true);
    const planList = await getPlans();
    setPlans(planList);
    dispatch(addPlans(planList));
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    if (reduxPlans.plans) {
      setPlans(reduxPlans.plans);
      setLoading(false);
    } else {
      getAllPlans();
    }
  }, [reduxPlans, getAllPlans]);

  // Map plans to the data structure for MUIDataTable
  const data = plans.map((plan) => ({
    id: plan.id,
    planName: plan.name || "--",
    amount: plan.amount || "--",
    insurerCode: plan.code || "--",
    price: getPriceFromRange(plan, 0),
    price2: getPriceFromRange(plan, 1),
    price3: getPriceFromRange(plan, 2),
    insurer: plan.insurerCode ? getInsurerName(plan.insurerCode) : "--",
    deductible: plan.deductible ? "Si" : "No",
    status: plan.isActive ? "Mostrado" : "No Mostrado",
    actions: plan.id,
  }));
  console.log(data);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: { display: "excluded", filter: false },
    },
    { name: "planName", label: "Nombre" },
    { name: "amount", label: "Monto" },
    { name: "price", label: "Precio (18-64)" },
    { name: "price2", label: "Precio (65-74)" },
    { name: "price3", label: "Precio (75+)" },
    { name: "insurer", label: "Aseguradora" },
    {
      name: "deductible",
      label: "Deducible",
      options: {
        customBodyRender: (value) => {
          return value === "Si" ? (
            <span className="text-active">Si</span>
          ) : (
            <span className="text-danger">No</span>
          );
        },
      },
    },
    {
      name: "status",
      label: "En la app",
      options: {
        customBodyRender: (value) => {
          return value === "Mostrado" ? (
            <span className="text-active">Mostrado</span>
          ) : (
            <span className="text-danger">No Mostrado</span>
          );
        },
      },
    },
    {
      name: "actions",
      label: "Acciones",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (planId) => {
          const handleEdit = () => navigate(`edit-plan/${planId}`);
          const handleDelete = () => callDeletePlan(planId);
          return (
            <Stack direction="horizontal" gap={2}>
              <IconButton onClick={handleEdit} size="small">
                <MdArrowOutward />
              </IconButton>
              <IconButton onClick={handleDelete} size="small">
                <IoRefreshOutline />
              </IconButton>
            </Stack>
          );
        },
      },
    },
  ];

  const handleRefresh = () => {
    getAllPlans();
  };

  const CustomToolbar = () => (
    <IconButton onClick={handleRefresh}>
      <IoRefreshOutline size={25} />
    </IconButton>
  );

  const options = {
    filter: true,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 15,
    customToolbar: CustomToolbar,
    textLabels: {
      body: {
        noMatch: "No hay datos disponibles",
      },
    },
  };

  const BodyComponent = useMemo(
    () => (tableProps) =>
      <CustomBodyWithSpinner loading={loading} {...tableProps} />,
    [loading]
  );

  return (
    <>
      <Typography variant="h5" component="h2" my={3}>
        Planes
      </Typography>

      <Box mt={6} sx={{ width: "100%" }}>
        <MUIDataTable
          title="Planes"
          data={data}
          columns={columns}
          options={options}
          components={{ TableBody: BodyComponent }}
        />
      </Box>
    </>
  );
};

export default PlansTableContainer;
