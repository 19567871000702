import { useState, useEffect, useMemo, useCallback } from "react";

import { ReactComponent as DarkPencilIcon } from "./../../assests/pencil.svg";
import { ReactComponent as DarkTrashCanIcon } from "./../../assests/trashcan.svg";
import { ReactComponent as DarkRoundedMenuIcon } from "./../../assests/rounded-hamburger-menu.svg";

import { Table, Spinner } from "react-bootstrap";
import { Stack } from "react-bootstrap";

import Swal from "sweetalert2";

import ReactPaginate from "react-paginate";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import TableHeader from "../table/table-header";
import TableBodyHover from "../table/table-body-hover";
import ItemsControl from "../items-control/items-control";
import TableFilters from "../table/table-filters";
import CoinLoader from "./../coin-loader/coin-loader";
import CustomModal from "../modal/customModal";

import { deleteProduct, getInsurers } from "../../firebase/firebase.utils";

import "./products-table.scss";

import { searchTable } from "../../custom hooks/search-table/search-table";
import { sortProductsTable } from "../../custom hooks/sort-table/sort-products-table";
import { useDispatch, useSelector } from "react-redux";
import { addInsurers } from "../../redux/insurers/actions/insurers";

import { useNavigate } from "react-router-dom";
import InsurerEditForm from "../insurersModal/insurerEditForm";

const InsurersTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxInsurers = useSelector((state) => state.insurers);

  const [insurers, setInsurers] = useState([]);
  const [filteredInsurers, setFilteredInsurers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentInsurer, setCurrentInsurer] = useState(null);

  const headers = [
    { name: "Nombre", accessor: "insurerName" },
    { name: "Descripcion", accessor: "description" },
    { name: "Acciones", accessor: "actions" },
  ];
  const headersSerch = [
    { name: "Nombre", accessor: "insurerName" },
    { name: "Descripcion", accessor: "description" },
  ];

  const handleClick = (id) => {
    const { insurers } = reduxInsurers;
    const selectedInsurer = insurers.find((insurer) => id === insurer.id);
    setCurrentInsurer(selectedInsurer);
    setShowModal(true);
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-danger me-2",
      cancelButton: "btn btn-secondary",
    },
    buttonsStyling: false,
  });

  const callDeleteProduct = (id) => {
    swalWithBootstrapButtons
      .fire({
        title: "¿Desea eliminar este producto?",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Eliminar",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteProduct(id);
          Swal.fire("Producto eliminado", "", "success");
          getAllInsurers();
        }
      });
  };

  const newInsurerList = useMemo(
    () =>
      filteredInsurers.map((insurer) => {
        return {
          id: insurer.id,
          insurerName: insurer.name ? insurer.name : "--",
          description: insurer.description ? insurer.description : "--",
          actions: (
            <Stack direction="horizontal" gap={2} key={insurer.id}>
              <span variant="secondary" size="sm" className="text-light">
                <DarkPencilIcon onClick={() => handleClick(insurer.id)} />
              </span>
              <span variant="secondary" size="sm" className="text-light">
                <DarkTrashCanIcon
                  onClick={() => callDeleteProduct(insurer.id)}
                />
              </span>
              <span variant="secondary" size="sm" className="text-light">
                <DarkRoundedMenuIcon />
              </span>
            </Stack>
          ),
        };
      }),
    [filteredInsurers]
  );

  const [itemsPerPage, setItemsPerPage] = useState(5);
  //const [filteringBy, setfilteringBy] = useState("Name");

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + Number(itemsPerPage);
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(newInsurerList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(newInsurerList.length / Number(itemsPerPage)));
  }, [itemOffset, newInsurerList, itemsPerPage]);

  const getAllInsurers = useCallback(async () => {
    setLoading(true);
    const insurerList = await getInsurers();

    setInsurers(insurerList);
    setFilteredInsurers(insurers);
    dispatch(addInsurers(insurerList));
  }, [dispatch, insurers]);

  useEffect(() => {
    setLoading(true);
    if (reduxInsurers.insurers) {
      const { insurers } = reduxInsurers;
      setInsurers(insurers);
      setFilteredInsurers(insurers);
      setLoading(false);
    } else {
      getAllInsurers();
    }
  }, [reduxInsurers]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % newInsurerList.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const sortTableElements = (sortingMethod) => {
    const sortedItems = sortProductsTable(currentItems, sortingMethod);
    setCurrentItems(sortedItems);
  };

  // const dropdownList = [
  //   {
  //     valuesList: [
  //       { name: "IVA + a -" },
  //       { name: "IVA - a +" },
  //       { name: "Precio + a -" },
  //       { name: "Precio - a +" },
  //     ],
  //     label: "",
  //     filterMethod: sortTableElements,
  //   },
  // ];

  // const showActiveProducts = (list) => {
  //   const activeProducts = list.filter((product) => product.isActive);

  //   setFilteredProducts(activeProducts);
  // };

  // const showInactiveProducts = (list) => {
  //   const inactiveProducts = list.filter((product) => !product.isActive);

  //   setFilteredProducts(inactiveProducts);
  // };

  // const showAllProducts = (list) => {
  //   setFilteredProducts(list);
  // };

  // const options = [
  //   { text: "Todos", filterMethod: () => showAllProducts(products) },
  //   { text: "Activos", filterMethod: () => showActiveProducts(products) },
  //   { text: "Inactivos", filterMethod: () => showInactiveProducts(products) },
  // ];

  const [searchField, setSearchField] = useState("");
  const rows = searchTable(currentItems, headersSerch, searchField);

  return (
    <>
      <TableFilters
        searchRows={setSearchField}
        handleRefresh={() => getAllInsurers()}
        openModal={() => setShowModal(true)}
        product={true}
      />
      <div className="p-4 bg-light">
        {loading ? (
          <div className="loading-container">
            <CoinLoader size={{ width: 150, height: 150 }} />
          </div>
        ) : (
          <Table
            className="bg-light mb-0 p-4"
            responsive
            style={{ width: "400px !important" }}
          >
            <TableHeader headers={headers} />
            <TableBodyHover
              rows={rows}
              columns={headers}
              // clickGoTo="/user/"
            />
          </Table>
        )}
      </div>

      <div className="bg-light p-4 rounded-bottom">
        <Stack direction="horizontal">
          <ItemsControl setNumItemPerPage={setItemsPerPage} />

          <div className="ms-auto">
            <ReactPaginate
              breakLabel="..."
              nextLabel={<IoIosArrowForward />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<IoIosArrowBack />}
              renderOnZeroPageCount={null}
              containerClassName="pagination pagination-modifier"
              pageClassName="page-item"
              previousClassName="previus-button"
              nextClassName="next-button"
              pageLinkClassName="page-link page-link-modifier"
              activeClassName="active"
            />
          </div>
        </Stack>

        {/* <div className="mt-4">
          <AdminHistory
            id={currentInsurer}
            getAdminHistory={getInsurerAdminHistory}
          />
        </div> */}

        <CustomModal
          show={showModal}
          handleClose={() => {
            setCurrentInsurer(null);
            setShowModal(false);
          }}
          modalTitle={() => "Aseguradora"}
        >
          <InsurerEditForm
            onClose={(val) => {
              setCurrentInsurer(null);
              setShowModal(val);
            }}
            handleRefresh={getAllInsurers}
            insurer={currentInsurer}
          />
        </CustomModal>
      </div>
    </>
  );
};

export default InsurersTable;
