import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Filters from "../filters/filters";
import Searchbar from "../searchbar/searchbar";
import { IoRefreshOutline } from "react-icons/io5";

import "./table.scss";
import "./table-filters.scss";

import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Tab, useMediaQuery } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";

const TableFilters = ({
  searchRows,
  dropdowns,
  options,
  handleRefresh,
  product,
  showArrows = false,
  openModal = false,
  showOnLargeScreens = false,
}) => {
  const [selectedButton, setSelectedButton] = useState(null);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const matchesUp576 = useMediaQuery("(min-width:576px)");

  const location = useLocation();

  console.log(product);
  const redirect = {
    pathname: product === "plan" ? "add-plan" : "add-product",
    state: { prevPath: location.pathname },
  };

  const noRedirect = {};

  const handleClick = (event, filterMethod) => {
    const selectedElementId = event.target.textContent;
    setSelectedButton(selectedElementId);

    filterMethod();
  };

  useEffect(() => {
    if (options) {
      setSelectedButton(options[0].text);
    }
  }, []);

  return (
    <div className="p-4 bg-light mt-4 rounded-top" id="table">
      <Stack direction="horizontal" gap={2}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          allowScrollButtonsMobile
          scrollButtons={showArrows}
          sx={{
            margin: "auto",
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          {options?.map((option) => (
            <Tab
              label={option.text}
              onClick={(event) => handleClick(event, option.filterMethod)}
            >
              <Button
                key={Math.random() * (100 - 1) + 1}
                variant="link"
                className={`${
                  selectedButton === option.text
                    ? "text-priamry"
                    : "text-secondary"
                } text-decoration-none`}
              >
                {option.text}
              </Button>
            </Tab>
          ))}
        </Tabs>
        <Stack
          direction="vertical"
          gap={2}
          className="ms-auto flex-sm-row"
          // className={`ms-auto flex-sm-row ${
          //   showOnLargeScreens
          //     ? "d-none d-md-block d-lg-none"
          //     : "d-block d-md-none d-lg-block"
          // }`}
        >
          {searchRows ? (
            <Searchbar
              onSearch={searchRows}
              barWidth="100%"
              containerClass={matchesUp576 ? "ms-auto d-flex" : "w-100"}
            />
          ) : null}
          <Stack
            direction="horizontal"
            gap={2}
            className="justify-content-between"
          >
            {dropdowns?.map((dropdown, index) => (
              <React.Fragment key={index}>
                <Filters
                  label={dropdown.label}
                  values={dropdown.valuesList}
                  sortingMethod={dropdown.filterMethod}
                />
              </React.Fragment>
            ))}

            {product ? (
              <Link to={openModal ? noRedirect : redirect}>
                <Button
                  onClick={openModal ?? openModal}
                  size="sm"
                  color="white"
                  className="rounded-pill add-product"
                >
                  Agregar
                </Button>
              </Link>
            ) : null}
            {handleRefresh ? (
              <div className="refresh-container">
                <Button className="refresh-button">
                  <IoRefreshOutline size={25} onClick={handleRefresh} />
                </Button>
              </div>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default TableFilters;
